<template>
    <div>
        <status-field v-bind="item.status_props" />
    </div>
</template>

<script>
import StatusField from '@/components/globals/StatusField';

export default {
    components: {
        StatusField,
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>